<template>
	<div class="h-100 dis-flex-com">
		<div class="dis-flex-row al-c txt-c w-100 tab-h bk-co-ff bd-bott" style="z-index: 999;">
			<div class="flx-1 pd-x-15">
				<div class="pd-y-15 po-r" :class="tabsStatus == 0 ? 'selectivity ft-co-39':''">
					开始工作
				</div>
			</div>
			<div class="flx-1 pd-x-15">
				<div class="pd-y-15 po-r" :class="tabsStatus == 1 ? 'selectivity ft-co-39':''" @click="tabsClick">
					个人中心
				</div>
			</div>
		</div>
		<img class="po-a w-100" src="../../../assets/image/changyue-bg.png" style="left: 0;bottom: 0;">
		<div class="pd-x-15 pd-t-15 ft-co-39 ft-w-b ft-sz-18">
			{{greetings}} 
			<span style="color: #fcc53d;" v-for="(item,index) in userData.departments" :key="item.id" v-if="item.departmentid!=122">{{index!=userData.departments.length-2?item.departmentname+',':item.departmentname}}</span>
			{{userData.user.realname != ''?userData.user.realname:userData.user.username}}
		</div>
		<div class="flx-1 dis-flex-com ju-c">
			<div class="po-r" style="bottom: 40px;">
				<div class="dis-flex-row ju-c po-r" style="bottom: 100px;">
					<div class="bk-co-linear bd-r-b50" style="padding: 20px;" @click="loginClick">
						<div class="img_dx txt-c dis-flex-com ju-c ft-w-b" style="border-radius: 50%;background-color: #FFFFFF;">
							<div class="ft-sz-28">
								{{myDateTime}}
							</div>
							<div class="ft-sz-18">
								开始工作
							</div>
						</div>
					</div>
				</div>
				<div class="" style="padding: 0 30px;">
					<div class="po-r pd-x-15 pd-b-15 bd-r-10" style="border: 1px solid #3390ff;">
						<div class="dis-flex-row ju-c">
							<div class="po-r bk-co-ff ft-sz-18" style="bottom: 24px;">
								工作说明
							</div>
						</div>
						<div class="ft-co-39 lin-26">
							<span class="pd-x-15"></span>
							点击开始工作，系统将给您自动派送需要您处理的稿件，稿件将按重点稿件，普通稿件的顺序依次派发，如经此期间出现相同稿件，表示此稿件的工作还没有达标，需要您继续努力！感谢您的辛勤工作
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import {
		Toast
	} from 'vant';
	var {
		changYueAcions,
		adminGeneral
	} = http
	export default {
		name: 'ChangYue',
		components: {},
		data() {
			return {
				imgs: window.imgs,
				tabsStatus: 0,
				myDateTime: '',
				userData: {
					departments:[],
					user: {
						realname: "",
						usermobile: "",
						username: "",
						wx_headimgurl: ""
					}
				},
				greetings: ''
			}
		},
		mounted() {
			var _that = this;
			var now = new Date(),
				hour = now.getHours()
			if (hour >= 5 && hour < 12) {
				_that.greetings = '早上好'
			} else if (hour < 18 && hour >= 12) {
				_that.greetings = '中午好'
			} else {
				_that.greetings = '晚上好'
			}
			window.setInterval(function() {
				_that.setTimeIn();
			}, 500);
			_that.userInfo();
		},
		methods: {
			tabsClick(e) {
				this.$router.push('/my')
			},
			loginClick() { /*开始工作*/
				var s = this;
				changYueAcions.listenSingle.http({
					"info": {
						"companyid": localStorage.getItem('companyid'),
						"productid": localStorage.getItem('productid'),
						"isonline": 1,
					},
				}).then(res => {
					if (res.getret === 0) {
						s.$router.push('/cycomments')
					} else {
						Toast(res.msg)
					}
				})
			},
			setTimeIn() {
				var _that = this;
				_that.myDateTime = _that.getNowDate();
			},
			getNowDate() { /*当前时间*/
				var myDate = new Date;
				var hours = myDate.getHours(); //获取当前小时
				if (hours < 10) {
					hours = '0' + hours
				}
				var minutes = myDate.getMinutes(); //获取当前分钟
				if (minutes < 10) {
					minutes = '0' + minutes
				}
				var seconds = myDate.getSeconds(); //获取当前秒
				if (seconds < 10) {
					seconds = '0' + seconds
				}
				var now = hours + ":" + minutes + ":" + seconds;
				return now;
			},
			userInfo() {
				var s = this;
				adminGeneral.userInfo.http({
					"companyid": localStorage.getItem('companyid'),
					"userid": s.user.userid,
				}).then(res => {
					if (res.getret === 0) {
						s.userData = res.info
						s.getDepartmentList();
					} else {
						Toast(data.msg || '获取数据失败')
					}
				})
			},
			getDepartmentList(){
				var s = this;
				var condition = {
					"companyid": localStorage.getItem('companyid'),
					'page_index':0,
					"page_size":20
				}
				adminGeneral.getDepartmentList.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						// res.list.forEach((item)=>{
						// 	console.log(item)
						// 	var condition = {
						// 		"companyid": localStorage.getItem('companyid'),
						// 		"userid": s.user.userid,
						// 		"departmentid":item.departmentid
						// 	}
						// 	adminGeneral.department.http({
						// 		condition
						// 	}).then(res => {
						// 		if (res.getret === 0) {
									
						// 		}
						// 	})
						// })
					}
				})
			}
		}
	}
</script>
<style scoped>
	.selectivity::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -4px;
		width: 100%;
		border-bottom: 2px solid #3390ff;
	}

	.bk-co-linear {
		background-image: linear-gradient(to bottom right, #3390ff, #89bfff);
		border-radius: 50%;
		padding: 10px;
	}

	.bg-img-h {
		height: 185px;
	}

	.img_dx {
		width: 120px;
		height: 120px;
		overflow: hidden;
	}

	.lin-26 {
		line-height: 26px;
	}
</style>

<template>
	<div class="h-100 dis-flex-com">
		<div class="flx-1 dis-flex-com">
			<div class="dis-flex-row al-c po-r">
				<div class="pd-x-10 h-100 po-a dis-flex-com ju-c" @click.stop style="left: 0;top: 0;z-index: 12;">
					<van-icon name="arrow-left" @click="navBarLeft" />
				</div>
				<van-dropdown-menu class="flx-1">
					<van-dropdown-item v-model="value" :options="option" />
					<van-dropdown-item title="筛选" ref="item">
						<van-cell center title="今天">
							<template #right-icon>
								<van-switch v-model="switch1" active-color="#ee0a24" />
							</template>
						</van-cell>
						<van-cell center title="昨天">
							<template #right-icon>
								<van-switch v-model="switch3" active-color="#ee0a24" />
							</template>
						</van-cell>
						<van-cell center title="自定义时间">
							<template #right-icon>
								<van-switch v-model="switch2" active-color="#ee0a24" />
							</template>
						</van-cell>
						<div v-if="switch2" class="pd-x-15 pd-t-10 pd-b-10 bk-co-f5">
							<div class="dis-flex-row al-c">
								<span class="ft-sz-14">开始：</span>
								<input class="txt-c flx-1 pd-y-10" type="text" readonly="readonly" placeholder="请选择开始日期" v-model="statarTime"
								 @click="openPicker('start')">
							</div>
							<div class="dis-flex-row al-c">
								<span class="ft-sz-14">截止：</span>
								<input class="txt-c flx-1 pd-y-10 mg-t-10" type="text" readonly="readonly" placeholder="请选择开始日期" v-model="endTime"
								 @click="openPicker('end')">
							</div>
							<div class="pd-t-10">
							      <van-button type="danger" block round @click="onConfirm">
							        确认
							      </van-button>
							    </div>
						</div>
					</van-dropdown-item>
				</van-dropdown-menu>
			</div>
			<div class="dis-flex-row al-c">
				<van-button class="flx-1 txt-c" style="padding:0;" type="primary">总评 </br> {{getALllistData.totalcommetnnum  || 0}}</van-button>
				<van-button class="flx-1 txt-c" style="padding:0;" type="info">通过 </br>{{(getALllistData.firstreviewpassnum + getALllistData.lastreviewpassnum) || 0}}</van-button>
				<van-button class="flx-1 txt-c" style="padding:0;" type="warning">待审 </br>{{getALllistData.waitreviewnum || 0}}</van-button>
				<van-button class="flx-1 txt-c" style="padding:0;" type="danger">拒绝 </br>{{(getALllistData.firstnopassnum + getALllistData.lastnopassnum) || 0}}</van-button>
			</div>
			<div class="flx-1 po-r">
				<div class="po-a w-100 h-100" @scroll="Scroll1" style="overflow: auto;left: 0;top: 0;">
					<div class="pd-y-10 po-r bd-bott" v-for="(item,index) in dataSource" :key="index">
						<div class="pd-x-10">
							<div class="ft-sz-16 ft-w-b ft-co-39" @click="clickUrl(item.manuscriptid)"> {{item.title}} </div>
							<div class="ft-sz-12 pd-t-5 ft-co-99">评论时间：{{util.DateFormatter(item.createtime*1000,'yyyy-MM-dd HH:mm')}}</div>
							<div class="ft-sz-12 pd-t-5 ft-co-99">评论内容：</div>
						</div>
						<div class="pd-x-10 pd-t-5 ft-co-99 ft-sz-14 commentsText" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">
							<span class="pd-x-15"></span> {{item.commentcontent}}
						</div>
						<img class="po-a img-tou1" v-if="item.status == 0" style="top: 20px;right: 20px;" src="../../../assets/image/tongguo1.png" alt="">
						<img class="po-a img-tou1" v-if="item.status == 1 || item.status == 3" style="top: 20px;right: 20px;" src="../../../assets/image/tongguo.png" alt="">
						<img class="po-a img-tou1" v-if="item.status == 2 || item.status == 4" style="top: 20px;right: 20px;" src="../../../assets/image/tongguo2.png" alt="">
					</div>
					<div class="txt-c ft-co-99 ft-sz-14 pd-y-10" v-if="flag2 && !loginShow">
						暂无数据
					</div>
					<div class="txt-c ft-co-99 ft-sz-14 pd-y-10" v-if="flag1 && !loginShow">
						没有更多数据了
					</div>
				</div>
			</div>
		</div>
		<van-overlay :show="loginShow" @click="show = false">
		  <div class="wrapper" @click.stop>
		    <van-loading />
		  </div>
		</van-overlay>
		<van-overlay :show="show" @click="show = false">
			<div class="w-100 h-100 dis-flex-com" style="justify-content: flex-end;">
				<div @click.stop>
					<van-datetime-picker v-model="currentDate" type="datetime" :title="dataTimeTitle" :min-date="minDate" :max-date="maxDate"
					 @confirm="dataTimeConfirm" @cancel="show = false" />
				</div>
			</div>
		</van-overlay>
	</div>
</template>
<style scoped>
	.van-icon {
		font-size: 18px !important;
	}

	.van-switch {
		font-size: 24px !important;
	}

	.van-overlay {
		z-index: 12 !important;
	}
	.wrapper {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    height: 100%;
	  }
</style>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import {
		DropdownMenu,
		DropdownItem
	} from 'vant';
	var {
		changYueAcions,
		adminGeneral
	} = http
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				util: util,
				endTime: '',
				statarTime: '',
				dataTimeTitle: '',
				currentDate: new Date(),
				minDate: new Date(2020, 0, 1),
				maxDate: new Date(2055, 10, 1),
				show: false,
				value: '0,1,2,3,4',
				switch1: true,
				switch3: false,
				switch2: false,
				option: [{
						text: '全部',
						value: '0,1,2,3,4'
					},
					{
						text: '待审',
						value: '0'
					},
					{
						text: '通过',
						value: '1,3'
					},
					{
						text: '拒绝',
						value: '2,4'
					},
				],
				dataSource: [],
				condition: {
					page_index: 0,
					page_size: 10
				},
				total: 0,
				flag1: false,
				flag2: false,
				loginShow: false,
				getALllistData:{
					delnum: 0,
					firstnopassnum: 0,
					firstreviewpassnum: 0,
					lastnopassnum: 0,
					lastreviewpassnum: 0,
					totalnum: 0,
					waitreviewnum: 0,
					totalcommetnnum:0
				},
				user:{}
			}
		},
		watch: {
			switch1: 'switchChange',
			switch2: 'switchChange1',
			switch3: 'switchChange2',
			value: 'valueChange'
		},
		created() {},
		mounted() {
			if(localStorage.getItem('user')){
				this.user = JSON.parse(localStorage.getItem('user'));
			}
			this.initData();
			this.init();
			this.userStatistical();
		},
		activated() {},
		methods: {
			switchChange(e) { /*监听*/
				if (e) {
					this.switch2 = false;
					this.switch3 = false;
					this.initData();
					this.init();
					this.userStatistical();
					this.$refs.item.toggle();
				}
			},
			switchChange1(e) { /*监听*/
				if(e){
					this.switch1 = false;
					this.switch3 = false;
				}
			},
			switchChange2(e) { /*监听*/
				if(e){
					this.switch1 = false;
					this.switch2 = false;
					this.initData1();
					this.init();
					this.userStatistical();
					this.$refs.item.toggle();
				}
			},
			valueChange(e) {
				this.init();
			},
			clickUrl(e) { /*新闻跳转*/
				this.$router.push('/cycomments/' + e);
			},
			init(e) {
				var s = this;
				s.loginShow = true;
				var {
					condition
				} = this;
				condition = Object.assign(condition, {
					"companyid": localStorage.getItem('companyid'),
					"poststartdate": s.statarTime,
					"postenddate": s.endTime,
					"userids": s.user.userid,
					"productid": localStorage.getItem('productid'),
					"status": s.value /*查询类型*/
				})
				changYueAcions.getCommentsList.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						if(res.condition.page_index == 0){
							s.dataSource = res.list
						}else{
							s.dataSource = s.dataSource.concat(res.list)
						}
						s.total = res.total;
						s.flag1 = false;
						if (res.list.length == 0) {
							s.flag2 = true;
						}
					}
					s.loginShow = false;
				})
			},
			userStatistical(){
				var s = this;
				var condition = {
					page_index: 0,
					page_size: 10
				};
				condition = Object.assign(condition, {
					"companyid": localStorage.getItem('companyid'),
					"poststartdate": s.statarTime,
					"postenddate": s.endTime,
					"productid": localStorage.getItem('productid'),
					"userid": s.user.userid,
					"departmentid":"-1"
				})
				changYueAcions.userStatistical.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						s.getALllistData = res.list[0]
						if(res.list.length == 0){
							s.getALllistData={
								delnum: 0,
								firstnopassnum: 0,
								firstreviewpassnum: 0,
								lastnopassnum: 0,
								lastreviewpassnum: 0,
								totalnum: 0,
								waitreviewnum: 0,
								totalcommetnnum:0
							}
						}
					}
				})
			},
			openPicker(val) { /*日期选择*/
				let s = this;
				if (val == 'end') {
					s.dataTimeTitle = '请选择结束时间';
				} else {
					s.dataTimeTitle = '请选择开始时间';
				}
				s.show = true;
			},
			dataTimeConfirm(val) {
				let s = this;
				if (s.dataTimeTitle == '请选择结束时间') {
					s.openPickerYes(val, '0')
				} else {
					s.openPickerYes(val, '1')
				}
				s.show = false;
			},
			onConfirm(){
				let s = this;
				s.init();
				s.userStatistical();
				s.$refs.item.toggle();
			},
			openPickerYes(e, val) {
				var date = new Date(e);
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				var hours = date.getHours();
				var mine = date.getMinutes();
				if (month < 10) {
					month = "0" + month;
				}
				if (day < 10) {
					day = "0" + day;
				}
				if (hours < 10) {
					hours = "0" + hours;
				}
				if (mine < 10) {
					mine = "0" + mine;
				}
				var nowDate = year + "-" + month + "-" + day + ' ' + hours + ':' + mine + ':00';
				if (val == '1') {
					this.statarTime = nowDate;
				} else {
					this.endTime = nowDate;
				}
			},
			Scroll1(e) {
				let s = this;
				const ele = e.srcElement ? e.srcElement : e.target
				// 监听滚动到div底部
				if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100) {
					if (s.flag1 === false) { //避免多次请求 加锁 该次请求完成 才能再次发起请求
						s.flag1 = true
						s.flag2 = false;
						if (s.total > s.dataSource.length) {
							s.condition.page_index++
							s.init();
						}
					}
				}
			},
			initData() {
				var chuo = new Date()
				var year = chuo.getFullYear();
				var month = chuo.getMonth() + 1;
				var day = chuo.getDate();
				var nowDate = year + "-" + month + "-" + day + ' 00:00:00';
				var date = new Date(nowDate.replace(/-/g, '/'))
				this.openPickerYes(date, '1');
				var date1 = date.getTime() + (24 * 60 * 60 - 50) * 1000
				var erdate = new Date(date1)
				this.value1 = localStorage.getItem('companyid') == 12 ? 122 : ''
				this.openPickerYes(erdate);
			},
			initData1() {
				var chuo = new Date()
				var year = chuo.getFullYear();
				var month = chuo.getMonth() + 1;
				var day = chuo.getDate()-1;
				var nowDate = year + "-" + month + "-" + day + ' 00:00:00';
				var date = new Date(nowDate.replace(/-/g, '/'))
				this.openPickerYes(date, '1');
				var date1 = date.getTime() + (24 * 60 * 60 - 50) * 1000
				var erdate = new Date(date1)
				this.value1 = localStorage.getItem('companyid') == 12 ? 122 : ''
				this.openPickerYes(erdate);
			},
			navBarLeft() {
				var s = this;
				s.$router.go(-1);
			},
		},
		destroyed() {}
	}
</script>

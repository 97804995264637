<template>
	<div class="po-a w-100 h-100 dis-flex-com po-r" style="top: 0;left: 0;">
		<div class="w-100 bk-co-f5">
			<div class="flx-1 pd-t-10 pd-b-10">
				<div class=" dis-flex-row al-c pd-b-10">
					<div class="pd-x-10" @click="pushGo">
						<van-icon name="arrow-left"/>
					</div>
					<div class="flx-1 pd-x-10 bk-co-ff">
						<input class="w-100 pd-y-10" v-model="username" placeholder="请输入用户姓名"></input>
					</div>
					<div class="pd-x-10" @click="souSuo">
						<van-icon name="search" />
					</div>
				</div>
				<div class="dis-flex-row pd-x-15 al-c pd-t-10 txt-c" style="border-top: 2px solid #FFFFFF;line-height: 52px;">
					<div class="">
						总计:
					</div>
					<div class="flx-1">
						<div class="dis-flex-row">
							<div class="flx-1">
								待审:{{getALllistData.waitreviewnum || 0}}
							</div>
							<div class="flx-1">
								通过:{{(getALllistData.firstreviewpassnum + getALllistData.lastreviewpassnum) || 0}}
							</div>
						</div>
						<div class="dis-flex-row">
							<div class="flx-1">
								拒绝:{{(getALllistData.firstnopassnum + getALllistData.lastnopassnum)  || 0}}
							</div>
							<div class="flx-1">
								总评:{{getALllistData.totalnum || 0}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flx-1" ref="evabox" @scroll="Scroll" style="overflow-y: auto;">
			<div class="pd-x-15 pd-t-10 pd-b-10 mg-t-10 bk-co-f5">
				<div class="dis-flex-row al-c">
					<span class="ft-sz-14">部门：</span>
					<div class="flx-1">
						<van-dropdown-menu>
						  <van-dropdown-item v-model="value1" :options="partmentList"  @change="changeMen"/>
						</van-dropdown-menu>
					</div>
				</div>
				<div class="dis-flex-row al-c mg-t-10">
					<span class="ft-sz-14">开始：</span>
					<input class="txt-c flx-1 pd-y-10" type="text" readonly="readonly" placeholder="请选择开始日期" v-model="statarTime" @click="openPicker('start')">
				</div>
				<div class="dis-flex-row al-c">
					<span class="ft-sz-14">截止：</span>
					<input class="txt-c flx-1 pd-y-10 mg-t-10" type="text" readonly="readonly" placeholder="请选择开始日期" v-model="endTime" @click="openPicker('end')">
				</div>
			</div>
			<div v-for="(item,index) in dataList" :KEY="index" class="mg-t-15 pd-x-15 bd-r-5" style="box-shadow: 0 0 5px 0 #999999;">
				<div class="pd-x-15 pd-t-15 ft-sz-16">
					{{item.nickname}} (<span class="ft-sz-12">编号:{{item.userid}}</span>)
				</div>
				<div class="dis-flex-row pd-t-15" style="line-height: 64px;">
					<div class="pd-x-15 pd-b-15 flx-1 dis-flex-com ju-c txt-c ft-sz-20">
						{{item.totalcommetnnum}}
					</div>
					<div class="pd-x-15 pd-b-15 ft-sz-14" style="flex: 3;">
						<div class="">
							待审核 : {{item.waitreviewnum}}
						</div>
						<div class="">
							已通过 : {{item.firstreviewpassnum}}
						</div>
						<div class="">
							被拒绝 : {{item.firstnopassnum}}
						</div>
					</div>
				</div>
			</div>
			<div class="txt-c ft-co-99 ft-sz-16 pd-y-10" v-if="flag && !loading">
				没有更多数据了
			</div>
		</div>
		<van-overlay :show="show" @click="show = false">
		  <div class="w-100 h-100 dis-flex-com" style="justify-content: flex-end;">
		    <div @click.stop>
				<van-datetime-picker
				  v-model="currentDate"
				  type="datetime"
				  :title="dataTimeTitle"
				  :min-date="minDate"
				  :max-date="maxDate"
				  @confirm="dataTimeConfirm"
				  @cancel="show = false"
				/>
			</div>
		  </div>
		</van-overlay>
		<van-overlay :show="loading">
		  <div class="w-100 h-100 dis-flex-com ju-c" @click.stop>
			  <div class="dis-flex-row ju-c">
				  <van-loading type="spinner" color="#1989fa" />
			  </div>
		  </div>
		</van-overlay>
	</div>
</template>

<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import {
		Toast,
		Overlay,
		Lazyload
	} from 'vant';
	var {
		changYueAcions,
		adminGeneral
	} = http
	export default {
		name: 'Statistical',
		components: {},
		data() {
			return {
				date:'',
				dataTimeTitle:'',
				currentDate:new Date(),
				minDate: new Date(2020, 0, 1),
				maxDate: new Date(2055, 10, 1),
				show:false,
				pickerValue: '',
				username: '',
				formItem: {},
				dataList: [],
				condition: {
					"page_index": 0,
					"page_size": 10,
				},
				statarTime: '',
				endTime: '',
				timeStatus: '1',
				getALllistData: {
					delnum: 0,
					firstnopassnum: 0,
					firstreviewpassnum: 0,
					lastnopassnum: 0,
					lastreviewpassnum: 0,
					totalnum: 0,
					waitreviewnum: 0
				},
				condition:{
					page_index:0,
					page_size:10
				},
				loading:false,
				flag:false,
				total:0,
				value1: '',
				partmentList: [],
				user:{}
			}
		},
		created(){
			if(localStorage.getItem('user')){
				this.user = JSON.parse(localStorage.getItem('user'));
			}
			this.userInfo();
		},
		mounted() {
			var chuo = new Date()
			var year = chuo.getFullYear();
			var month = chuo.getMonth() + 1;
			var day = chuo.getDate();
			var nowDate = year + "-" + month + "-" + day + ' 00:00:00';
			var date = new Date(nowDate.replace(/-/g, '/'))
			this.openPickerYes(date,'1');
			var date1 = date.getTime()+(24*60*60-50)*1000
			var erdate = new Date(date1);
			// this.value1 = localStorage.getItem('companyid') == 12 ? '' : ''
			// if(localStorage.getItem('companyid') == 12 && this.user.userid==2838){
			// 	this.value1=122
			// }
			// else if(localStorage.getItem('companyid') == 12 && this.user.userid==3534){
			// 	this.value=135
			// }
			// else{
			// 	this.value1 = localStorage.getItem('companyid') == 12 ? '' : ''
			// }
			this.openPickerYes(erdate);	
		},
		methods: {
			souSuo() { /*搜索*/
			    this.condition.page_index = 0;
				this.userDataAll();
			},
			userInfo() { /*用户信息*/
				var s = this;
				adminGeneral.userInfo.http({
					"companyid": localStorage.getItem('companyid'),
					"userid": s.user.userid,
				}).then(res => {
					if(res.getret === 0){
						if(res.info.user_company.islead == 1){
							s.getpartmentList();
						}else{
							let temp = [];
							if(res.info.departments && res.info.departments.length>0){
								res.info.departments.forEach((item,index)=>{
									if(item.islead == 1){										
										temp.push({
											text : item.departmentname,
											value :item.departmentid
										});
									}
								})
								s.value1 = temp[0].value;
							}else{
								s.value1 = "";
							}
							s.partmentList = temp;
							s.userDataAll();
						}
						
					}
				})
			},
			userDataAll(){/*按用户统计*/
				var s = this;
				s.loading = true;
				var {condition} = s;
				condition = Object.assign(condition, {
					"companyid": localStorage.getItem('companyid'),
					"poststartdate":s.statarTime,
					"postenddate":s.endTime,
					"username":s.username,
					"productid": localStorage.getItem('productid'),
					"departmentid":s.value1
				})
				changYueAcions.userStatistical.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						s.loading = false;
						s.flag = false;
						if(res.condition.page_index == 0){
							s.dataList = res.list;
						}else{
							s.dataList = s.dataList.concat(res.list)
						}
						s.total = res.total;
						s.dataAll();
					}else{
						s.dataList = [];
					}
				})
			},
			dataAll(){/*统计总评论数*/
				var s = this;

				changYueAcions.commentsDataAll.http({
					"condition":{
						"companyid": localStorage.getItem('companyid'),
						"productid": localStorage.getItem('productid'),
						"poststartdate":s.statarTime,
						"postenddate":s.endTime,
						"departmentid":s.value1
					},
				}).then(res => {
					if (res.getret === 0) {
						s.getALllistData = res.countlist;
					}
				})
			},
			getpartmentList(){/*部门查询*/
				var s = this;
				adminGeneral.getDepartmentList.http({
					"condition":{
						"companyid": localStorage.getItem('companyid'),
						page_index:0,
						page_size:50
					},
				}).then(res => {
					if (res.getret === 0) {
						// if(s.user.userid==2838){////甘肃团队
						// 	s.partmentList=[{text:'甘肃远程兼职团队',value:'122'}]

						// }else if(s.userid==3534){//北大团队
						// 	s.partmentList=[{text:'北大驻场团队',value:'135'}]
						// }
						// else{
							res.list.forEach((v,k)=>{
								v.text = v.departmentname;
								v.value = v.departmentid;
							})
							s.partmentList = [{text:'全部',value:'-1'}].concat(res.list);
							s.value1 = s.partmentList[0].value;
						// }
							s.userDataAll();
					}
				})
			},
			changeMen(e){
				this.userDataAll();
			},
			pushGo(){/*返回*/
				this.$router.go(-1);
			},
			formatDate(date) {
			      return `${date.getMonth() + 1}/${date.getDate()}`;
			    },
			openPicker(val){/*日期选择*/
				let s = this;
				if(val == 'end'){
					s.dataTimeTitle = '请选择结束时间';
				}else{
					s.dataTimeTitle = '请选择开始时间';
				}
				s.show = true;
			},
			dataTimeConfirm(val){
				let s = this;
				if(s.dataTimeTitle == '请选择结束时间'){
					s.openPickerYes(val,'0');
					s.condition.page_index = 0;
					s.userDataAll();
				}else{
					s.openPickerYes(val,'1')
				}
				s.show = false;
			},
			openPickerYes(e,val){
				var date = new Date(e);
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				var hours = date.getHours();
				var mine = date.getMinutes();
				if (month < 10) {
				    month = "0" + month;
				}
				if (day < 10) {
				    day = "0" + day;
				}
				if (hours < 10) {
				    hours = "0" + hours;
				}
				if (mine < 10) {
				    mine = "0" + mine;
				}
				var nowDate = year + "-" + month + "-" + day + ' ' + hours + ':' + mine + ':00';
				if(val == '1'){
					this.statarTime = nowDate;
				}else{
					this.endTime = nowDate;
				}
			},
			Scroll(e){
				let s = this;
				const ele = e.srcElement ? e.srcElement : e.target
				 // 监听滚动到div底部
				if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100) {
				  if (s.flag === false) {//避免多次请求 加锁 该次请求完成 才能再次发起请求
					s.flag = true
					if(s.total > s.dataList.length){
						s.condition.page_index ++
						s.userDataAll();
					}
				  }
				}
			}
		}
	}
</script>
<style scoped>
	button {border: initial;color:initial;border-radius:initial;background: initial;}
	button:focus {outline: initial;}
	.van-icon{font-size: 18px !important;}
	.kongbai-my{height: 132px;}
</style>
